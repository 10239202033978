import { StateMachineProvider } from "little-state-machine";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Container from "./layouts/container";
import Wrapper from "./layouts/wrapper";
import Layout from "./layouts/layout";
import Dashboard from "./pages/user/dashboard";
import Insure from "./pages/user/insure";
import InviteFriend from "./pages/user/invite-friend";
import Invoice from "./pages/broker/invoice";
import Launch from "./pages/user/launch";
import SignIn from "./pages/user/sign-in";
// import UserLogin from "./pages/user/login";
import Login from "./pages/user/login";
import Sales from "./pages/broker/sales";
import Products from "./pages/broker/products";
import Broker from "./layouts/broker";
import SendMaterial from "./pages/user/send-material";
import RecoverPassword from "./pages/recover-pass";
import Auth from "./layouts/auth";
import ComingSoon from "./pages/coming-soon";
import InsuranceProcess from "./pages/insurance-process";

function App() {
  return (
    <StateMachineProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Container />}>
            <Route path="broker" element={<Wrapper />}>
              <Route path="" element={<Broker />}>
                <Route path="" element={<Products />} />
                <Route path="sales" element={<Sales />} />
                <Route path="coming-soon" element={<ComingSoon />} />
              </Route>
              <Route path="invoice" element={<Invoice />} />
              <Route path="insurance-process" element={<InsuranceProcess />}>
                <Route path=":id" element={<InsuranceProcess />} />
              </Route>
              <Route path="" element={<Auth />}>
                <Route path="login" element={<Login />} />
              </Route>
            </Route>
            <Route path="launch" element={<Launch />} />
            <Route path="/" element={<Layout />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="insure" element={<Insure />} />
              <Route path="invite-friend" element={<InviteFriend />} />
              <Route path="coming-soon" element={<ComingSoon />} />
            </Route>
            <Route path="send-material" element={<SendMaterial />}>
              <Route path=":policy_id" element={<SendMaterial />} />
            </Route>
            <Route path="insurance-process" element={<InsuranceProcess />}>
              <Route path=":id" element={<InsuranceProcess />} />
            </Route>
            <Route path="/" element={<Auth />}>
              <Route path="sign-in" element={<SignIn />} />
              <Route path="login" element={<Login />}>
                <Route path=":product_id" element={<Login />} />
              </Route>
              <Route path="recover-password" element={<RecoverPassword />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </StateMachineProvider>
  );
}

export default App;
