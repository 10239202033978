import React, { forwardRef } from "react";
import { PulseLoader } from "react-spinners";
import { twMerge } from "tailwind-merge";
import Typography from "../Typography";

interface IButton {
  className?: string;
  variant?: "primary" | "secondary" | "outlinePrimary" | "outlineSecondary" | "softPrimary" | "softSecondary" | "outlinePrimaryNoneBorder";
  size?: "sm" | "lg";
  icon?: React.ReactNode;
  icon2?: React.ReactNode;
  textSize?: "sm" | "md" | "lg";
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type: "submit" | "button" | "reset";
  loading?: boolean;
  disabled?: boolean;
}

export const IButton = forwardRef<HTMLButtonElement, IButton>((props, ref) => {
  const {
    className,
    variant = "primary",
    size = "lg",
    icon,
    icon2,
    textSize = "lg",
    loading = false,
    disabled = false,
    ...rest
  } = props;

  const generalStyles = [
    "px-4 rounded-lg text-white flex justify-center items-center font-medium tracking-[.25px] outline-none space-x-2",
  ];

  const small = ["text-xs py-3 h-10"];
  const large = ["text-sm py-3.5 h-12"];

  const variants = {
    primary: [
      "bg-primary",
      "focus:bg-primary-focus focus:text-on-surface-white-64",
      "[&:hover:not(:disabled)]:bg-primary-hover [&:hover:not(:disabled)]:text-on-surface-white-64",
      "disabled:bg-primary-disabled disabled:text-on-surface-black-24",
    ],
    secondary: [
      "bg-secondary",
      "focus:bg-secondary-focus",
      "[&:hover:not(:disabled)]:bg-secondary-hover",
      "disabled:bg-secondary-disabled disabled:text-on-surface-black-24",
    ],
    outlinePrimary: [
      "border border-stroke-dark-12 bg-white text-primary",
      "focus:bg-primary-soft-while-pressing focus:text-on-surface-black-64",
      "[&:hover:not(:disabled)]:bg-primary-soft-while-pressing [&:hover:not(:disabled)]:text-on-surface-black-64",
      "disabled:bg-primary-disabled disabled:text-on-surface-black-24",
    ],
    outlinePrimaryNoneBorder: [
      "focus:bg-primary-soft-while-pressing focus:text-on-surface-black-64",
      "[&:hover:not(:disabled)]:bg-primary-soft-while-pressing [&:hover:not(:disabled)]:text-on-surface-black-64",
      "disabled:bg-primary-disabled disabled:text-on-surface-black-24",
    ],
    outlineSecondary: [
      "border border-stroke-secondary-20 bg-white text-on-surface-secondary",
      "focus:bg-secondary-soft-focus focus:border-stroke-secondary-12",
      "[&:hover:not(:disabled)]:bg-secondary-soft-while-pressing [&:hover:not(:disabled)]:border-stroke-secondary-12",
      "disabled:bg-secondary-disabled disabled:text-on-surface-black-24 disabled:border-stroke-secondary-12",
    ],
    softPrimary: [
      "bg-primary-soft text-primary",
      "focus:bg-primary-soft-focus",
      "[&:hover:not(:disabled)]:bg-primary-soft-hover",
      "disabled:bg-primary-disabled disabled:text-on-surface-black-24",
    ],
    softSecondary: [
      "bg-secondary-soft text-on-surface-secondary",
      "focus:bg-secondary-soft-focus",
      "[&:hover:not(:disabled)]:bg-secondary-soft-hover",
      "disabled:bg-secondary-disabled disabled:text-on-surface-black-24",
    ],
  };

  return (
    <button
      {...rest}
      disabled={loading || disabled}
      ref={ref}
      className={twMerge([
        generalStyles,
        size === "sm" && small,
        size === "lg" && large,
        variants[variant],
        className,
      ])}
      type={props.type}
    >
      {icon}
      {loading ? (
        <PulseLoader color="#BBC1C9" speedMultiplier={0.7} size={12} />
      ) : (
        props.children && (
          <Typography variant="Label" size={textSize}>
            {props.children}
          </Typography>
        )
      )}
      {icon2}
    </button>
  );
});

IButton.displayName = "Button";
