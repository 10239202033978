import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

interface ITypography {
  variant?: string;
  size?: string;
  type?: string;
  className?: string;
  children?: React.ReactNode;
}

const Typography = forwardRef((props: ITypography, ref) => {
  const { variant = "Body", size = "lg", type = "primary", className } = props;

  return (
    <>
      {variant === "Display" && (
        <h1
          {...props}
          className={twMerge([
            size === "lg" &&
            "text-[62px] leading-[64px] font-bold tracking-[1.25px]",
            size === "md" &&
            "text-[49px] leading-[52px] font-semibold tracking-[0.01em]",
            size === "sm" &&
            "text-4xl leading-[44px] font-semibold tracking-[0.0025em]",
            className,
          ])}
        >
          {props.children}
        </h1>
      )}
      {variant === "Headline" && (
        <h2
          {...props}
          className={twMerge([
            size === "lg" &&
            "text-[32px] leading-[40px] font-bold tracking-[0.01em]",
            size === "md" &&
            "text-[28px] leading-[36px] font-bold tracking-[0.005em]",
            size === "sm" && "text-2xl font-bold tracking-[0.0025em]",
            className,
          ])}
        >
          {props.children}
        </h2>
      )}
      {variant === "Title" && (
        <h3
          {...props}
          className={twMerge([
            size === "lg" &&
            "text-[22px] leading-[28px] font-semibold tracking-[0.005em]",
            size === "md" && "text-base font-semibold tracking-[0.2px]",
            size === "sm" && "text-sm font-semibold tracking-[0.2px]",
            className,
          ])}
        >
          {props.children}
        </h3>
      )}
      {variant === "Label" && (
        <h4
          {...props}
          className={twMerge([
            size === "lg" && "text-sm font-medium tracking-[0.25px]",
            size === "md" && "text-xs font-medium",
            className,
          ])}
        >
          {props.children}
        </h4>
      )}
      {variant === "Body" && (
        <h5
          {...props}
          className={twMerge([
            size === "lg" && "text-base font-light tracking-[0.25px]",
            size === "md" && "text-sm font-light tracking-[0.2px]",
            size === "sm" && "text-xs font-light",
            className,
          ])}
        >
          {props.children}
        </h5>
      )}
    </>
  );
});
Typography.displayName = "Typography";

export default Typography;
