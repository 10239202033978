import { forwardRef, useImperativeHandle, useState } from "react";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";
import { ReactComponent as Close } from "../../assets/icon/cross.svg";
import { ReactComponent as Success } from "../../assets/icon/check-circle-2.svg";
import { ReactComponent as Info } from "../../assets/icon/info.svg";
import { ReactComponent as Warning } from "../../assets/icon/alert-circle.svg";
import { ReactComponent as Error } from "../../assets/icon/cross-circle.svg";
import Typography from "../Typography";
import { useStore } from "../../stores";

const Notification = forwardRef(
  ({ type = Types.info, message = "", title = "Алдаа гарлаа" }, ref) => {
    const [showNotification, setShowNotification] = useState(false);
    const setNotification = useStore.useSetNotification();

    useImperativeHandle(ref, () => ({
      show() {
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
          setNotification(null);
        }, 5000);
      },
    }));

    return (
      <div className="fixed bottom-auto right-0 top-6 z-50 m-0 mr-6 box-border p-0">
        <div
          className={twMerge([
            "relative ms-auto max-h-[800px] w-[384px] max-w-[calc(100vw-48px)] overflow-hidden break-words rounded-lg bg-white px-5 py-6 shadow-notication",
            showNotification ? "block" : "hidden",
          ])}
        >
          <div className="relative box-border flex space-x-4">
            <div>
              {type === Types.info && <Info stroke="#1865D8" />}
              {type === Types.success && <Success stroke="#0C6E3D" />}
              {type === Types.warning && <Warning stroke="#E3A13A" />}
              {type === Types.error && <Error stroke="#B7281E" />}
            </div>
            <div className="w-[307px] space-y-2 text-primary">
              <Typography variant="Title" size="md">
                {title}
              </Typography>
              <Typography size="md" className="max-[136px] overflow-auto">
                {message}
              </Typography>
            </div>
            <button className="absolute -right-1 -top-1 rounded-full p-1 hover:bg-primary-soft">
              <Close
                fill="#0A213A"
                className="h-5 w-5"
                onClick={() => setShowNotification(false)}
              />
            </button>
          </div>
        </div>
      </div>
    );
  },
);

export const Types = {
  info: "info",
  success: "success",
  warning: "warning",
  error: "error",
};

Notification.propTypes = {
  notificationType: PropTypes.oneOf(Object.keys(Types)),
  children: PropTypes.element,
};

export default Notification;
