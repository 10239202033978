import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useStateMachine } from "little-state-machine";

import updateAction from "../../updateAction";
import dayjs from "dayjs";

import { useStore } from "../../../stores";

import Object from "./object";
import OwnerInfo from "./daatguulagch";
import PolicyInfo from "./geree";
import MzField from "./mz-talbar";
import ConfirmInfo from "./batalgaajuulah";
import Summary from "./summary";

import AlertModal from "../../../base-components/AlertModal";
import Button from "../../../base-components/Button";
import { IButton } from "../../../components/IButton";

import PolicyInfoService from "../../../services/PolicyInfoService";
import PriceService from "../../../services/PriceService";

const Groups = ({
  currentStep,
  setCurrentStep,
  steps,
  productHasService = false,
  currency = "₮",
  setCurrency,
  stepConfig,
}) => {
  const currentDate = dayjs().startOf("day");
  const { actions } = useStateMachine({ updateAction });
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      objects: [], // zuvhun id-nuud hadgaladag object
      view_objects: [], // front tald haragdah object
      plate_number: "", // Xyp
      cabin_number: "", // Xyp
      insurance_partner: {
        is_company: 0,
      },
      is_foreclosured: "no",
      dynamic_column: {},
      showContinue: true,
      market_price: "",
      extra_service: [],
      package: {},
      policy_id: "",
      inception_datetime: currentDate,
      expiry_datetime: currentDate.add(1, "day"),
      base_payment: 0,
      extra_price: 0,
      amount: 0,
      qpay_mobile: "",
      registrationNumber: "",
    },
  });
  const [selectedCountry, setselectedCountry] = useState([]);
  const extraService = methods.watch("extra_service");
  const { id } = useParams();
  const prevPath = useStore.usePrevPath();
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [loaderPolicy, setLoaderPolicy] = useState(false);
  const calcAmount = useCallback(
    (value) => {
      let basePayment = value || methods.watch("base_payment");
      let price = methods
        .watch("extra_service")
        .filter((x) => x.isSelected)
        ?.reduce((acc, data) => acc + Number(data.amount), 0);
      let amount = Number(price) + Number(basePayment);

      methods.setValue("extra_price", price);
      methods.setValue("base_payment", basePayment);
      methods.setValue("amount", amount);
    },
    [methods],
  );

  useEffect(() => {
    let service_ids = [];
    methods
      .watch("extra_service")
      .filter((x) => x.isSelected)
      ?.forEach((service) => {
        service_ids.push(service.id);
      });
    methods.setValue(`service_ids`, service_ids);
    calcAmount();
  }, [calcAmount, extraService, methods]);

  /**
   * str зураг эсэхийг шалгах
   * @param {String} str тэмдэгт мөр
   * @returns boolean
   */
  const isBase64Image = (str) => {
    const base64ImagePattern =
      /^data:image\/(png|jpg|jpeg|gif|bmp|webp);base64,/;
    return base64ImagePattern.test(str);
  };

  /**
   * Object-д байгаа зурагнуудыг хасах
   * @param {Object} object object
   * @returns Object-оос зурагнуудыг хассан үлдэгдэл elements
   */
  const clearObjectfromImage = (object) => {
    let clonedObject = { ...object };
    if (clonedObject && typeof clonedObject === "object") {
      for (const key in clonedObject) {
        if (isBase64Image(clonedObject[key])) {
          delete clonedObject[key];
        }
      }
    }
    return clonedObject;
  };

  const gettingPrice = () => {
    let data = methods.getValues();
    let market_price = 0;
    let value = data?.market_price;
    if (typeof value === "string")
      market_price = Number(value.replace(/,/g, ""));

    let insurance_policy_lines = [];
    data.object_ids?.forEach((object) => {
      if (object.id !== null && object.id !== undefined) {
        insurance_policy_lines.push({
          object_id: object.id,
          market_price: Number(market_price),
          peril_ids: [],
          service_ids: [],
          amount: 0,
          is_foreclosured: "",
          mortgagor_name: "",
        });
      }
    });
    let payload = {
      dynamic_product_id: id,
      insurance_partner_id: data.insurance_partner?.id,
      package_id: data.package?.id,
      inception_datetime: dayjs(data.inception_datetime)?.format(
        "YYYY-MM-DD HH:mm:ss",
      ),
      expiry_datetime: dayjs(data.expiry_datetime)?.format(
        "YYYY-MM-DD HH:mm:ss",
      ),
      insurance_policy_lines: insurance_policy_lines,
      dynamic_column: clearObjectfromImage(data?.dynamic_column),
      covid_options: data?.covid_options,
    };

    let config = { baseURL: process.env.REACT_APP_DYNAMIC_API_URL };
    PriceService.getPrice(payload, config)
      .then((response) => {
        if (response.status === "success") {
          calcAmount(response.value.price);
        }
        if (response.error)
          useStore.setState({
            notification: { message: response.error, type: "warning" },
          });
      })
      .catch((error) => {
        console.log(error, "error------------------->");
      });
  };
  const savingInsurancepolicy = () => {
    setLoaderPolicy(true);
    let data = methods.getValues();
    if (typeof data.market_price === "string")
      data.market_price = data.market_price.replace(/,/g, "");

    let insurace_policy_lines = [];
    data.object_ids?.forEach((object) => {
      insurace_policy_lines.push({
        object_id: object.id,
        market_price: Number(data.market_price),
        peril_ids: data.peril_ids,
        service_ids: data.service_ids,
        amount: data.base_payment,
        is_foreclosured: data.is_foreclosured,
        mortgagor_name: data.mortgagor_name,
      });
    });

    let params = {
      uploaded: true,
      dynamic_insurance_product_id: id,
      package_id: data.package.id,
      inception_datetime: data.inception_datetime?.format(
        "YYYY-MM-DD HH:mm:ss",
      ),
      expiry_datetime: data.expiry_datetime?.format("YYYY-MM-DD HH:mm:ss"),
      insurance_partner_id: data.insurance_partner.id,
      insurace_policy_lines: insurace_policy_lines,
      duration_id: data.duration_id,
      day: "duration_day" in data ? data.duration_day : 0,
      first_country_code:
        "first_country_code" in data ? data.first_country_code : "",
      mz_field_values: data.dynamic_column,
      confirmed_coupon_code: data.confirmed_coupon_code,
      additional_country_code:
        "additional_country_code" in data ? data.additional_country_code : [],
    };
    // хэсэв covid options байвал insurance policy үүсгэхэд дата нэмнэ
    if (data.covid_options || data.covid_options === 0) {
      params.covid_options = data.covid_options;
    }
    PolicyInfoService.savingInsurancePolicy(params)
      .then((response) => {
        if (response.status === "success") {
          methods.setValue("policy_id", response.policy_id);
          methods.setValue("sequence", response.sequence);
          if (response?.total_price > 0 && response?.coupon_value > 0) {
            methods.setValue("amount", response.total_price);
            methods.setValue("coupon_amount", response.coupon_value);
          }
          data.policy_id = response.policy_id;
          data.sequence = response.sequence;
          actions.updateAction({ ...data, dynamic_column: {} });
          setCurrentStep(currentStep + 1);
          setIsShowAlert(false);
          setLoaderPolicy(false);
        }
        if (response.status === "error") {
          useStore.setState({
            notification: { message: response.message, type: "warning" },
          });
          setLoaderPolicy(false);
        }
      })
      .catch((error) => {
        setLoaderPolicy(false);
        console.log(error, "khadgalakhad aldaa garlaa");
      });
  };
  const onSubmit = (data) => {
    setIsShowAlert(true);
  };

  return (
    <div
      className={twMerge([
        "flex justify-center p-14 max-[841px]:px-10 max-[500px]:px-4",
        prevPath === "/insure" && "lg:w-[77%] xl:w-4/5",
      ])}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="gap-10 max-[900px]:w-full"
        >
          {steps?.map((data, index) => (
            <React.Fragment key={index}>
              {currentStep !== steps?.length
                ? currentStep >= data.sequence && (
                    <>
                      {data.name === "Объект" && (
                        <Object
                          {...{
                            currentStep,
                            setCurrentStep,
                            title: data.title,
                            steps,
                            productHasService,
                          }}
                        />
                      )}
                      {data.name === "Даатгуулагч" && (
                        <OwnerInfo
                          {...{
                            currentStep,
                            setCurrentStep,
                            title: data.title,
                            steps,
                            stepConfig,
                          }}
                        />
                      )}
                      {data.name === "Гэрээ" && (
                        <PolicyInfo
                          {...{
                            currentStep,
                            setCurrentStep,
                            steps,
                            gettingPrice,
                            currency,
                            setCurrency,
                            selectedCountry,
                            setselectedCountry,
                            stepConfig,
                          }}
                        />
                      )}
                      {data.name === "МЗ талбар" && (
                        <MzField
                          {...{
                            currentStep,
                            setCurrentStep,
                            sequence: data.sequence,
                            title: data.title,
                            steps,
                            gettingPrice,
                          }}
                        />
                      )}
                    </>
                  )
                : currentStep === data.sequence &&
                  !data.name && <ConfirmInfo {...{ currency }} />}
            </React.Fragment>
          ))}
          {currentStep === steps.length - 1 &&
            methods.watch("insurance_partner.id") &&
            methods.watch("object_ids") && (
              <Summary setIsShowAlert={setIsShowAlert} currency={currency} />
            )}
        </form>
      </FormProvider>

      {currentStep === steps.length - 1 && (
        <AlertModal
          type="info"
          isOpen={isShowAlert}
          title="Гэрээ баталгаажуулахдаа итгэлтэй байна уу?"
          footer={
            <div className="flex w-full space-x-4">
              <Button
                variant="softPrimary"
                className="w-full"
                onClick={() => setIsShowAlert(false)}
              >
                Үгүй
              </Button>
              <IButton
                className="w-full"
                onClick={savingInsurancepolicy}
                loading={loaderPolicy}
              >
                Тийм
              </IButton>
            </div>
          }
        />
      )}
    </div>
  );
};

export default Groups;
